
















































import { Component, Vue } from 'vue-property-decorator';
import KuspitAccountLinkingProcessViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-process-view-model';

@Component({
  name: 'KuspitAccountLinkingProcess',
  components: {
    KuspitAccountLinkingSuccess: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingSuccess.vue'),
    KuspitAccountLinkingError: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingError.vue'),
    KuspitAccountLinkingAccountWithoutContract: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/KuspitAccountLinkingAccountWithoutContract.vue'),
    kuspitAccountLinkingWrongCredentials: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/KuspitAccountLinkingWrongCredentials.vue'),
  },
})
export default class KuspitAccountLinkingProcess extends Vue {
  kuspit_process_view_model = Vue.observable(
    new KuspitAccountLinkingProcessViewModel(this),
  );

  created() {
    this.kuspit_process_view_model.initialize();
  }
}
