import Vue from 'vue';
import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class KuspitAccountLinkingProcessViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  i18n_namespace = 'components.contract-savings.kuspit-account-linking.account_linking_process';

  is_open = true;

  is_loading = true;

  is_successful = false;

  wrong_credentials = false;

  account_without_contract = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    window.addEventListener('kuspit.my_investments.kuspit.account_linking.status_update',
      (event: CustomEventInit) => {
        if (event.detail.percent === 100 || event.detail.has_problem) {
          this.is_open = false;
          this.is_successful = !event.detail.has_problem;
          this.is_loading = false;
          this.wrong_credentials = event.detail.status === 'wrong_credentials';
          this.account_without_contract = event.detail.status === 'account_without_contract';
        }
      });
  }

  firstStep = () => {
    this.view.$emit('firstStep');
  }

  endProcess = () => {
    this.view.$emit('endProcess');
  }

  endSuccessProcess = () => {
    this.view.$emit('endSuccessProcess');
  }
}
